import React from 'react'
import Layout from '../../components/Layout'
import {Location} from '@reach/router'
import contentImage from '../../images/ADC_smart_thermostat.jpg'
import Content from '../../components/Content';
export default () => (  
    <Location>
        {
            props=>(
                <Layout location={props.location}>
                    <Content>
                    <h2>
                        Smarter Energy Savings
                    </h2>
                    <p>
                        US Alarms connects your thermostats and lighting to your security system for a smarter, more responsive set of options for saving energy.
                    </p>
                    <div className="row center">
                        <img src={contentImage} style={{maxWidth:'350px'}}/>
                    </div>
                                        <h2>
                                            Automatic Savings
                                        </h2>
                    <p>
                        Manage your lights and temperature settings with automated schedules. Create easy rules to have an added layer of smart automation based on system activity. Have your thermostat automatically set back when the system is armed away, or pause if smoke or carbon monoxide is detected to limit the circulation of fumes.
                    </p>

                    <h2>
                        Mobile Control
                    </h2>
                    <p>
                        Adjust lights and thermostats from home or while you’re away, with complete control through your Alarm.com app or any computer.
                    </p>

                    <h2>
                        Lock-Out & Alerts
                    </h2>
                    <p>
                        Stay in control of your energy settings even when you’re away. Get a notification if the thermostat setting is adjusted, or if the temperature in your property gets too high or too low. You can also enable a local lock-out so that others can’t adjust the thermostat.
                    </p>

                    <h2>
                        Extreme Temperatures
                    </h2>
                    <p>
                        Want to avoid overpaying for energy on extremely hot or cold days? US Alarms can use real-time local weather data to adjust your temperature settings to save energy and money if outdoor temps spike.
                    </p>
                    </Content>
                </Layout>
            )
        }
    </Location>
)